// ==================================================
//  スクロールに合わせて線を引く
// ==================================================
$(window)
  .on("scroll load", function () {
    const windowScrollTop = $(window).scrollTop();
    const windowInnerHeight = window.innerHeight;

    const $aboutBorder = $(".about_border-js");
    const aboutBorderTop = $aboutBorder.offset().top;
    let startPoint = 400;
    let calculationPoint = 75;
    if (is_mobile()) {
      startPoint = 200;
      calculationPoint = 50;
    }

    if (windowScrollTop >= aboutBorderTop - windowInnerHeight - startPoint) {
      const H = $aboutBorder.parent().outerHeight(true);
      let percent = ((windowScrollTop + startPoint - aboutBorderTop) / (H / 2)) * calculationPoint;

      if (percent > 100) percent = 100;

      $aboutBorder.css({
        height: percent + "%",
      });
    }
  })
  .trigger("scroll");
